<template>
  <div class="">

    <h3 class="page-header" v-translate>Mentor</h3>
    <br>


    <v-data-table dense
                  :headers="headers"
                  :items="users"
                  :items-per-page="10"
                  class="elevation-1"
                  :loading="loading" >
      <!--      TODO: paging, options-->
      <!--                  :server-items-length="total"-->
      <!--                  :options.sync="options"-->
      <template v-slot:item.lastLogin="{ item }">
        {{ ymd(item.lastLogin) }}
      </template>

      <template v-slot:item.learnStatus="{ item }">
        <span v-for="v,k in item.learnStatus"><span v-if="v">{{k}} </span></span>
      </template>

      <template v-slot:item.tasks="{ item }">
        <span v-for="v in item.tasks"><span v-if="v && v.cnt>0">{{v.id}}&nbsp;<span v-if="v.cnt > 1">({{v.cnt}})</span></span>&nbsp; </span>
      </template>

      <template v-slot:item.tasksAvailable="{ item }">
        {{ item.tasksAvailable.length }}
      </template>

      <template v-slot:item.tasksDone="{ item }">
        {{ taskStats(item) }}
      </template>

      <template v-slot:item.skillLevels="{ item }">
        <span v-for="sk in item.skillLevels">
        {{ store.skillLevelName(sk) || '-'}}
        </span>
<!--        {{ item.skillLevel ? item.skillLevel + '. ' + store.skillLevelName(item.skillLevel) : '-'}}-->
      </template>

    </v-data-table>


    <br><br>
    <div v-if="user">
      <h4 v-translate>Signup Codes</h4>
      <span v-for="c in user.mentorCodes" :key="'code'+i+c.code">
        <div>{{c.code}}: {{ store.skillLevelName(c.skillLevel) }} {{c.status ? '':' (inactive)'}}
          ({{ mentorStats[c.code] }})
          <v-icon small class="mr-2" @click="setEdit(c)">mdi-pencil</v-icon>
          <v-icon v-if="mentorStats[c.code]==0" small class="mr-2" @click="deleteCode(c.code)">mdi-trash-can</v-icon>
        </div>
      </span>

      <v-btn v-if="canAddCode()" @click="showNew=!showNew;edit=0" style="margin-bottom: 20px;"><translate>Add</translate></v-btn>


      <div v-if="showNew" class="edit-box">
        <v-text-field style="width:100px;" v-model="code" :rules="[store.rules.required, store.rules.minCode]"
                      outlined :label="$gettext('New Signup Code')" ></v-text-field>
        <v-select style="width:300px;"
                  :items="store.state.info.SkillLevels"
                  :label="$gettext('skill level')" outlined
                  :item-text="i => store.skillLevelName(i.id)"
                  item-value="id" v-model="skillLevel"></v-select>

        <v-btn @click="newCode(code, skillLevel)"><translate>New Code</translate></v-btn>
      </div>

      <div v-else-if="edit"  class="edit-box">
        <v-select :items="store.state.info.SkillLevels" style="width:300px;"
                  :label="$gettext('skill level') + ': ' + edit" outlined
                  :item-text="i => store.skillLevelName(i.id)"
                  item-value="id" v-model="skillLevel"></v-select>
        <v-checkbox v-model="status" label="active"/>
        <v-btn @click="editCode(edit, skillLevel, status)"><translate>Save</translate></v-btn>
      </div>

    </div>

    <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>
  </div>
</template>


<script>


import _ from "lodash";

export default {
  name: 'AdminUsers',
  components: {
  },
  data: () => ({
    user: {},
    code: '',
    skillLevel: 1,
    status: 1,
    error: '',
    users: [],
    total: 0,
    edit: 0,
    loading: true,
    showNew: false,
    mentorStats: {},
    i:0,
    options: {},
    headers: [
      { text: 'username', value: 'username' },
      { text: 'email', value: 'email' },
      { text: 'phone', value: 'phone' },
      { text: 'signup code', value: 'mentorCode' },
      // { text: 'english', value: 'readsEnglish' },
      { text: 'group/skill', value: 'skillLevel' },
      { text: 'last login', value: 'lastLogin' },
      // { text: 'status', value: 'status' },
      // { text: 'dmiles', value: 'dmiles' },
      // { text: 'bonus', value: 'bonus' },
      { text: 'revenue', value: 'revenue' },
      { text: 'learn', value: 'learnStatus' },
      { text: 'tasks', value: 'tasks' },
      { text: 'done', value: 'tasksDone', sortable:false },
      { text: 'avail', value: 'tasksAvailable', sortable: false },
    ],
  }),
  methods: {
    load() {
      this.loading = true
      this.store.content(true)

      // const { sortBy, sortDesc, page, itemsPerPage } = this.options
      this.store.mentorUsers(0).then(data => {
        this.users = data.users
        this.total = data.total
        this.mentorStats = data.stats
        this.loading = false
      })
          // .catch(err => this.$router.replace('/'))
    },
    ymd(dt) { return (dt ? new Date(dt) : new Date()).toISOString().slice(0, 10) },

    newCode(code, skillLevel) {
      this.store.newMentorCode(code, skillLevel).then(res => {
        this.error = res.error || ''
        if (!this.error) {
          this.code = ''
          this.showNew = false
          this.mentorStats[code] = 0
        }
      })
    },
    setEdit(c) {
      if (!c || c.code == this.edit) return this.edit=0
      this.showNew=0
      this.edit = c.code
      this.skillLevel = c.skillLevel
      this.status = c.status
    },
    editCode(code, skillLevel, status) {
      this.store.editMentorCode(code, code, skillLevel, status? 1 : 0).then(res => {
        this.error = res.error || ''
        if (!this.error) {
          this.code = ''
          this.edit = 0
        }
      })
    },
    async deleteCode(code) {
      await this.store.deleteMentorCode(code)
      _.remove(this.user.mentorCodes, {code:code})
      this.i++
    },
    canAddCode() {
      return true
      // return this.user && this.user.mentorCodes && this.user.mentorCodes.length < 10
    },
    taskStats(u) {
      return (_.filter(u.tasks, t => t.cnt > 0).length || '-')
    }
  },
  async beforeMount() {
    await this.load()
    this.user = this.store.state.user
    if (!this.user) this.$router.replace('/login').catch(e => console.log(e))
    if (!this.store.isMentor()) this.$router.replace('/').catch(e => console.log(e))
  },
}
</script>


<style>
.desc {
  white-space: pre-line;
}
.edit-box {
  display: flex;
}
.edit-box > * {
  padding: 5px;
}
/*.icn {*/
/*  color: gray;*/
/*  cursor: pointer;*/
/*}*/
/*.icn:hover {*/
/*  color: white;*/
/*}*/
</style>
