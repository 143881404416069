<template>
  <div class="">
    <v-card>
      <v-card-title >
        Admin

      <admin-toggle />
<!--
       <v-icon style="color:white;position:absolute;right:20px;margin-left:10px;" @click="reload()">mdi-refresh</v-icon>
-->
      </v-card-title>
      <v-card-text>

        <h4>Skill Levels</h4><br/>

        <v-row v-for="s in skills" :key="s.id"  @click="openEdit(s)">
          <v-col sm="1" class="sm-col clk">{{ s.id}}</v-col>
          <v-col sm="3" class="sm-col clk">{{ s.group }} </v-col>
          <v-col sm="3" class="sm-col clk">{{ s.name }}
<!--            <v-icon @click="openEdit(s)" class="clk" style="font-size:14px;margin-left:10px;">fa-edit</v-icon>-->
          </v-col>
        </v-row>

        <br/>
        <v-btn @click="openNew()" class="primary" v-translate>New</v-btn>
        <br><br>

      </v-card-text>
    </v-card>

      <div v-if="edit" class="mentor-outer" @click="edit=null">
        <v-card class="mentor-box edit-box" @click.stop="">
          <v-icon @click="edit=null" style="color:black; position:absolute;right:10px;">fa fa-times</v-icon>
          <h4>Edit {{edit.id || ' - NEW'}}</h4>
          <v-select :items="groups" v-model="edit.group" :label="$gettext('Group')" />

          <v-text-field style="width:200px;" v-model="edit.newgroup"
                        :label="$gettext('New Group')" ></v-text-field>

          <v-text-field style="width:200px;" v-model="edit.name" :rules="[store.rules.required]"
                        :label="$gettext('Skill Level')" ></v-text-field>

          <v-btn @click="save(edit)">{{ edit.id ? 'Save' : 'Add' }}</v-btn> &nbsp;
          <v-btn v-if="edit.id" @click="del(edit)" class="error" v-translate>Delete</v-btn> &nbsp;
          <v-btn @click="edit=null" class="secondary" v-translate>Close</v-btn>
        </v-card>
      </div>

  </div>
</template>

<style>
.mentor-box {
  position: fixed;
  top: 100px;
  left: 20px;
  border: 1px solid gray;
  border-radius: 5px;
  background: white;
  padding: 10px;
}
.mentor-outer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, .5);
}

</style>

<script>

import _ from "lodash";
import AdminToggle from "@/views/AdminToggle";

export default {
  name: 'AdminMentors',
  components: { AdminToggle },
  data: () => ({
    error: '',
    total: 0,
    loading: true,
    edit: null,
    groups: [],
    skills: [],
  }),

  methods: {
    async reload() {
      await this.store.content(true)

      this.skills = _.sortBy(this.store.state.info.SkillLevels, s => s.group)
      this.groups = _.uniq(_.map(this.store.state.info.SkillLevels, s => s.group))
    },

    openEdit(m) {
      this.edit = _.clone(m)
    },
    openNew() {
      this.edit = { name:'', group:'', id:0 }
    },

    save() {
      if (!this.edit.name) return alert('name required')
      if (this.edit.newgroup) this.edit.group = this.edit.newgroup

      this.store.editSkill(this.edit.id, this.edit.group, this.edit.name).then(res => {
        this.error = res.error || ''
        if (!this.error) {
          // let mc = _.find(this.mentorCodes, {username: this.edit.username})
          // if (mc) {
          //   let m = _.find(mc.mentorCodes, {code: this.edit.id})
          //   m.code = this.edit.code
          //   m.status = this.edit.status
          //   m.skillLevel = this.edit.skillLevel
          // }
          this.reload()
          this.edit = null
        }
      })
    },
    async del(e) {
      if (confirm(`delete? ${e.group} / ${e.name} ?`)) {
        await this.store.deleteSkill(e.id)
        // let mc = _.find(this.mentorCodes, {username: this.edit.username})
        // _.remove(mc.mentorCodes, {code: code})
        this.edit = null
      }
    },
  },
  async beforeMount() {
    await this.reload()
    await this.store.reloadUser(true)

    if (! this.store.isAdmin()) return this.$router.replace('/').catch(e => console.log(e))
  },
}

</script>

