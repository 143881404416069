<template>
  <div class="login">
    <v-icon @click="back()" style="color:gray; position:absolute;right:100px;">fa fa-times</v-icon>
    <h3>Edit Earn #{{ id || 'New'}}: {{e.code}}</h3>
    <br>

    <v-form @submit="edit" ref="form">
      <v-row>
        <v-col class="col-md-6 col-sm-6">
          <v-checkbox v-model="e.status" label="active"></v-checkbox>
          <v-text-field v-model="e.code" :rules="[submitRules.required]" outlined label="Code" ></v-text-field>
          <v-text-field v-model="e.order" outlined type="number" label="Order #" ></v-text-field>

          <v-select v-model="e.level" :items="[1,2,3]" outlined type="number" label="Level"></v-select>

          <v-text-field v-model="e.title" :rules="[submitRules.required]" outlined label="Title" ></v-text-field>
          <v-textarea v-model="e.intro" outlined label="Intro" ></v-textarea>
          <v-textarea v-model="e.description" outlined label="Description" ></v-textarea>
          <v-textarea v-model="e.details" outlined label="Details when Joined" ></v-textarea>

          <v-text-field class="col-md-2 col-sm-2" :rules="[submitRules.required]" v-model="e.amount" type="number" outlined label="Amount" ></v-text-field>

          <v-select outlined :items="tokens" :item-text="r => r.symbol + ' - ' + r.code"
                    :item-value="r => r.code" v-model="e.token"
                    :label="$gettext('Token')" class="col-md-2 col-sm-2" ></v-select>

          <v-textarea v-model="e.videoId" outlined label="Youtube Video Id or Embed code"></v-textarea>

<!--          <v-checkbox v-model="e.requireEnglish" label="require english"></v-checkbox>-->

          <v-select :items="store.state.info.GroupsSkillLevels"  label="group/skill levels" outlined multiple
                    :item-text="i => store.skillLevelName(i.id)" item-value="id" v-model="e.skillLevels"></v-select>

          <br>

          <v-text-field v-model="e.numAvailable" outlined label="Total available" type="number" ></v-text-field>
          <v-text-field v-model="e.maxPerUser" outlined label="Max per User" type="number" ></v-text-field>
          <v-text-field v-model="e.maxUsers" outlined label="Max Users can join" type="number" ></v-text-field>

          <v-select :items="SkillLevels"  label="on complete add to skill level" outlined
                    :item-text="i => addSkillLevelName(i.id)" item-value="id" v-model="e.addToSkillLevel"></v-select>


<!--          <v-checkbox v-model="e.userSubmit" v-if="store.config.ShowUserSubmit" label="User submission"></v-checkbox>-->
          <v-btn class="secondary" v-if="!e.userSubmit" @click="addUserSubmit()" >allow user submit</v-btn>

          <v-card v-if="e.userSubmit" class="user-submit-settings" :key="ii">
            <v-card-title >User Submit Settings</v-card-title>
            <v-card-text>
              <v-text-field v-model="e.userSubmit.title" outlined label="title" />
              <v-textarea v-model="e.userSubmit.description" outlined label="description" :rows="3" />

              <v-textarea v-model="e.userSubmit.validatorInfo" outlined label="info for validators" :rows="3" />

              <v-text-field v-model="e.userSubmit.end" @change="saveEnd()" outlined label="submit ends (optional)" type="datetime-local" :clearable="true"/>

              <v-text-field v-model="e.userSubmit.numValidators" :rules="[submitRules.required]" outlined label="validators need to vote" type="number" size="2" />
              <v-text-field v-model="e.userSubmit.minRequired" :rules="[submitRules.required]" outlined label="required to decide" type="number" size="2" />
              <v-btn-toggle multiple dense v-model="e.userSubmit.fields">
                <v-btn value="text">text field</v-btn>
                <v-btn value="textBox">text box</v-btn>
                <v-btn value="image">image upload</v-btn>
                <v-btn value="videoUrl">video url</v-btn>
                <v-btn value="social">social</v-btn>
              </v-btn-toggle>

              <v-text-field v-model="e.userSubmit.text" v-if="haveField(e, 'text')" outlined label="text field label" />
              <v-text-field v-model="e.userSubmit.textBox"  v-if="haveField(e, 'textBox')" outlined label="text box label" />
              <v-text-field v-model="e.userSubmit.image"  v-if="haveField(e, 'image')" outlined label="image label" />
              <v-text-field v-model="e.userSubmit.videoUrl"  v-if="haveField(e, 'videoUrl')" outlined label="video url label" />
              <v-text-field v-model="e.userSubmit.social"  v-if="haveField(e, 'social')" outlined label="social label" />

              <span @click="e.userSubmit=null; ii++" class="clk"> cancel <v-icon style="color:gray">fa-times</v-icon></span>
            </v-card-text>
          </v-card>
<!--          {{ e.userSubmit }}-->

          <br/>

          <v-btn color="primary" @click="edit" type="submit">{{ id ? 'Save' : 'Add'}}</v-btn>
          <v-btn color="error" @click="deleteEntry" type="submit">Delete</v-btn>
          <v-btn  @click="back" >Back</v-btn>

          <pulse-loader :loading="store.state.loading" color="#7131FF" :key="'loadingx'+ii"></pulse-loader>

          <br/>
          <div class="error" v-if="error">{{ error }}</div>
          <br/>
          <div class="info-txt">* max settings ignored when empty</div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<style>
.info-txt { font-size: 12px; }
.amt-inp {
  width: 100px;
}
.user-submit-settings {
  /*display: flex;*/
  /*padding: 5px;*/
}
.user-submit-settings > div {
  padding: 5px 10px;
}

span.clk:hover {
  /*text-display: underline;*/
}
</style>

<script>
import {getIdFromURL} from "vue-youtube-embed";
import _ from 'lodash'
import Vue from 'vue'
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: 'Register',
  components: {
    PulseLoader
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    e: {
      // symbol: 'PUSDT',  // TODO.
      level: 1,
      skillLevels: [ ],
      maxPerUser: 1,
      maxUsers: null,
      numAvailable: null,
      amount: 1,
      userSubmit: null,
      // numValidators: 3,
      // minRequired: 2,
    },
    tokens: null,
    error: '',

    showEdit: false,
    SkillLevels: [],
    ii: 0,

    submitRules: {
      required: value => !!value || 'Required',
      nonZero: value => value != 0 || 'Non Zero',
    }
  }),

  methods: {
    edit(e) {
      console.log('edit', this.e)
      e.preventDefault()
      if (! this.$refs.form.validate()) return

      // extract youtube videoId from url?
      if (this.e.videoId && this.e.videoId.match(/^https:\/\/\w+?\.youtube\.com/)) {
        this.e.videoId = getIdFromURL(this.e.videoId)
      }
      this.e.code = this.e.code.replaceAll(/ /g, '_')
      this.e.maxPerUser = parseInt(this.e.maxPerUser || 0) || null
      this.e.maxUsers = parseInt(this.e.maxUsers || 0) || null
      this.e.numAvailable = parseInt(this.e.numAvailable || 0) || null
      this.e.amount = parseFloat(this.e.amount || 1)

      this.store.saveContent('earn', this.e)
          .then(res => {
            this.error = res.error || ''
            console.log('save', res.error, res.status)

            if (!this.e.id && res.id) this.id = this.e.id = res.id

            // if (res.status === 'ok') this.$router.push('/earn/' + this.e.skillLevel + '/0', { _skillLevel: this.e.skillLevel})
          })
    },
    async deleteEntry(e) {
      console.log('delete', this.e)
      e.preventDefault()
      if (confirm('delete?')) {
        await this.store.deleteContent('earn', this.e.id)
        this.back()
      }
    },

    haveField(earn, field) {
      return earn && earn.userSubmit && earn.userSubmit.fields && earn.userSubmit.fields.indexOf(field) !== -1
    },

    back() {
      this.$router.back()
    },

    addUserSubmit() {
      if (! this.e.userSubmit) this.e.userSubmit = {
        numValidators: 3,
        minRequired: 2,
        end: null,
        labels: {},
        fields: ['textBox', 'image'],
      }

      this.ii++
    },

    addSkillLevelName(id) {
      return id == 0 ? '- none -' : this.store.skillLevelName(id)
    }
  },
  beforeMount() {
    console.log('mount', this.id)

    if (this.id > 0) {
      this.e = this.store.earnId(this.id)
    } else {
      this.e.skillLevels = [ -1 * this.id ]
      this.id = 0
    }

    if (this.e && !this.e.token) this.e.token = 'PUSDT'

    if (this.store.state.info) {
      this.tokens = this.store.state.info.tokens
      this.SkillLevels = [ { id:0, name:'none' }, ...this.store.state.info.SkillLevels ]
    }
  }
}
</script>
