<template>
  <div class="">
    <v-card>
      <v-card-title >
        Admin
        <v-icon style="color:white;margin-left:10px;" @click="reload()">mdi-refresh</v-icon>

        <admin-toggle />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
        <v-select :items="SkillLevels" label="admin: group/skill" dense outlined
                  :item-text="i => i.id + '. ' + store.skillLevelName(i.id)"
                  item-value="id"
                  v-model="skillLevel" @change="load()" style="width:220px;"></v-select>
          </v-col>
          <v-col>
            <v-btn v-model="showActive" @click="showActive=!showActive;reload()" class="toggle-btn"
                   v-translate>active</v-btn>
            &nbsp;
            <v-btn @click="newTask()" class=" primary" v-translate>new</v-btn>

          </v-col>
        </v-row>

        <h4>Tasks</h4>
        <v-row>
          <v-col sm="3">code</v-col>
          <v-col class="text-right">amount</v-col>
          <v-col class="text-right">available</v-col>
          <v-col class="text-right">max per user</v-col>

          <v-col class="text-right">joined</v-col>
          <v-col class="text-right">completed</v-col>
          <v-col class="text-right">users completed</v-col>
        </v-row>

        <v-row v-for="t in tasks" :key="'t'+t.id">
          <v-col sm="3" class="clk" @click="edit(t)">{{ t.code}}</v-col>
<!--          <v-col>{{store.getSymbol(t.token) }}{{ t.amount}}</v-col>-->
          <v-col class="text-right">{{ store.fmtAmount(t.amount, t.token) }}</v-col>
          <v-col class="text-right">{{ t.numAvailable || '-'}}</v-col>
          <v-col class="text-right">{{ t.maxPerUser || '-'}}</v-col>
          <v-col class="text-right">{{ t.usersJoined || '-'}}</v-col>
          <v-col class="text-right">{{ t.totalCompleted || '-'}}</v-col>
          <v-col class="text-right">{{ t.usersCompleted || '-'}}</v-col>
        </v-row>
      </v-card-text>
    </v-card>


  </div>
</template>



<script>

import _ from "lodash";
import AdminToggle from "@/views/AdminToggle";

export default {
  name: 'AdminMentors',
  components: { AdminToggle },
  data: () => ({
    error: '',
    total: 0,
    loading: true,
    tasks: [],
    skillLevel: 1,
    showActive: true,

    SkillLevels: [],
  }),

  methods: {
    async load() {
      await this.store.content(true)
      await this.reload()
    },
    async reload() {
      const data = await this.store.content(true, this.skillLevel, true)
      this.tasks = _.sortBy(_.filter(data.earn || [], {status: this.showActive ? 1 : 0}), 'code')
    },

    edit(e) {
      this.$router.push('/earn-edit/' + (e.id || -1 * e.skillLevel))
    },
    newTask() {
      this.$router.push('/earn-edit/' + (-1 * this.skillLevel))
    }
  },
  async beforeMount() {
    // this.skillLevel = 1
    await this.load()
    await this.store.reloadUser(true)
    if (! this.store.isAdmin()) return this.$router.replace('/').catch(e => console.log(e))

    this.SkillLevels = [{ id:0 }].concat(this.store.state.info.SkillLevels)
  },
}

</script>



