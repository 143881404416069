<template>
  <div class="login">
    <h3 class="page-header" v-translate>Admin Login</h3>

    <div>

    </div>

    <br>

    <v-form @submit="login">
      <v-row>
        <v-col class="col-md-4 col-sm-8">
          <v-text-field v-model="username" outlined :label="$gettext('Username or Phone')"
                        :rules="[store.rules.required]"  @change="error=null"
          ></v-text-field>
          <v-text-field v-model="pin" outlined :label="$gettext('Pin')"
                        :append-icon="showPin ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[store.rules.required, store.rules.minPin]"
                        :type="showPin ? 'text' : 'password'"
                        @click:append="showPin = !showPin" @change="error=null"
          ></v-text-field>
          <v-btn class="primary" :disabled="username=='' || pin==''" @click="login" type="submit">Login</v-btn>

          <v-btn @click="$router.back()" style="margin-left:10px;">Back</v-btn>

          <br><br>
          <!--          <router-link to="/register" style="margin:10px;">Sign Up with password</router-link>-->
          <router-link to="/login" style="margin:10px;">User - Login</router-link>

          <br/><br/>
<!--          <h4>Login With</h4>-->
<!--          <span v-for="provider in store.config.oreAuth" >-->
<!--            <v-btn primary @click="oreLogin(provider)" class="provider-btn">{{ provider }}</v-btn>-->
<!--          </span>-->


          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>


<script>

export default {
  name: 'Login',
  components: {
  },
  data: () => ({
    username: '',
    pin: '',
    error: '',
    showPin: false,
  }),
  methods: {
    login(e) {
      console.log('login', this.username, this.pin)
      e.preventDefault()

      let self = this
      grecaptcha.ready(function() {
        grecaptcha.execute(self.store.config.captchaKey, {action: 'submit'}).then(function (captcha) {
          self.store.login(self.username, self.pin, captcha)
              .then(res => {
                self.error = res.error || ''
                console.log('login:', res.error||'', res.status, res.next||'-')
                if (res.status === 'ok') {
                  let next = '/'
                  if (res.next === 'verifyPhone') next = '/verify-phone'
                  self.$router.replace(next).catch(e => console.log(e))
                }
                else {
                  self.error = res.error
                }
              })
        })
            .catch(err => { self.error = err || 'Captcha Error' })
      })

    },
    oreLogin(p) {
      this.error = ''
      this.store.oreStartLogin(p).then(r => {
        console.log('r', r)
        if (r && r.error) this.error = r.error
        if (r && r.next) this.$router.replace(r.next).catch(e => console.log(e))
      })
    }
  },
  beforeMount() {
    // console.log('c?', this.store.haveToken())
  }
}
</script>

