<template>
  <div class="">
    <v-card>
      <v-card-title >
        Admin
<!--        <v-spacer></v-spacer>-->
        <div class="search-div">
          <v-text-field
              v-model="search"
              :append-icon="search ? 'fa-times':''"
              append-outer-icon="mdi-magnify"
              label="Search"
              single-line dense hide-details
              @change="getDataFromApi(1)"
              @click:append-outer="getDataFromApi(1)"
              @click:append="doSearch('')"
              class="search"
          ></v-text-field>
        </div>
        <span class="search-total">{{ total }}</span>

        <div class="country-div">
          <v-text-field
              size="2"
              v-model="country"
              :append-icon="country ? 'fa-times':''"
              label=""
              single-line dense hide-details
              @change="getDataFromApi(1)"
              @click:append="searchCountry('')"
              class="search"
          ></v-text-field>
        </div>

        <div class="mentorcodesearch-div">
          <v-text-field
              v-model="mentorCodeSearch"
              :append-icon="mentorCodeSearch ? 'fa-times':''"
              label="mentor code"
              single-line dense hide-details
              @change="getDataFromApi(1)"
              @click:append="searchMentorCode('')"
              class="search"
          ></v-text-field>
        </div>


        <v-row class="skills-div" v-if="store.state.info && skillLevel">
<!--          <v-col sm="1">-->

          <!--          </v-col>-->
<!--          <v-col sm="3">-->
          <v-select :items="store.state.info.SkillLevels"  label="skill" dense
                  :item-text="i => store.skillLevelName(i.id)" item-value="id"
                  v-model="skillLevel" @change="searchSkill(skillLevel)"
                    class="skills-sel">
          </v-select>
          <v-icon @click="searchSkill(0)" style="width:20px;" class="clk">fa fa-times</v-icon>

          <!--          </v-col>-->
        </v-row>

<!--        <v-btn @click="showStats()" dense>stats</v-btn>-->

        <admin-toggle />

      </v-card-title>


      <v-data-table dense
          :headers="headers"
          :items="users"
          :search="search"
          :items-per-page="10"
          :footer-props="{ 'items-per-page-options': [10,25,50,100,-1], showFirstLastPage: true }"
          class="elevation-1 row-clk"
          :loading="loading"
                    :server-items-length="total"
                    :options.sync="options"
                    @click:row="editItem"
      >

        <!-- TODO: how to make whole row clickable? -->
<!--        <template v-slot:item.name="{ item }">-->
<!--          <span @click="editItem(item)" class="clk">{{ item.name }}</span>-->
<!--        </template>-->
<!--        <template v-slot:item.email="{ item }">-->
<!--          <span @click="editItem(item)" class="clk">{{ item.email }}</span>-->
<!--        </template>-->

        <template v-slot:item.mentor="{ item }">
          <span @click.stop="doSearch(item.mentor)" class="clk">{{ item.mentor }}</span>
        </template>

        <template v-slot:item.mentorCode="{ item }">
          <span @click.stop="searchMentorCode(item.mentorCode)" class="clk">{{ item.mentorCode }}</span>
        </template>

        <template v-slot:item.country="{ item }">
          <span @click.stop="searchCountry(item.country)">{{ item.country }}</span>
        </template>

        <template v-slot:item.lastLogin="{ item }">
          {{ item.lastLogin ? ymd(item.lastLogin) : '-'}}
        </template>

        <template v-slot:item.signupDate="{ item }">
          {{ item.signupDate ? store.fmtDateTime(item.signupDate) : '-'}}
        </template>

        <template v-slot:item.learnStatus="{ item }">
          <span v-for="v,k in item.learnStatus"><span v-if="v">{{ store.learnTitle(k) }} </span></span>
        </template>

        <template v-slot:item.revenue="{ item }">
          {{ item.revenue ? parseFloat(item.revenue.toFixed(4)) || '' : '' }}
        </template>

        <template v-slot:item.tasks="{ item }">
<!--          <span v-for="v,k in item.earnStatus"><div v-if="v && v.cnt>0">{{k}}<span v-if="v.cnt > 1">({{v.cnt}})</span> </div></span>-->
          <span v-for="v in item.tasks"><div v-if="v && v.cnt>0" @click.stop="doSearch(v.id)">{{v.id}}<span v-if="v.cnt > 1">&nbsp;({{v.cnt}})</span> </div></span>
        </template>

        <template v-slot:item.tasksDone="{ item }">
          {{ taskStats(item) }}
        </template>

        <template v-slot:item.tasksAvailable="{ item }">
          {{ item.tasksAvailable.length }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        </template>

<!--        <template v-slot:item.skillLevel="{ item }">-->
<!--          <span @click.stop="searchSkill(item.skillLevel)">-->
<!--            {{ item.skillLevel ? item.skillLevel + ':&nbsp;' + store.skillLevelName(item.skillLevel) : '-'}}-->
<!--          </span>-->
<!--        </template>-->

        <template v-slot:item.skillLevels="{ item }">
          <span v-for="sk in item.skillLevels" @click.stop="searchSkill(sk)">
            {{ store.skillLevelName(sk) }}
          </span>
        </template>

        <template v-slot:item.phoneCode="{ item }">
          {{ item.status < 2 ? item.phoneCode : '' }}
        </template>

        <template v-slot:item.status="{ item }">
          {{ item.status >= 10 ? 'blocked' : item.status }}
        </template>

        <template v-slot:item.bal.PUSDT.liquid="{ item }">
          {{ fmtAmount(item.bal && item.bal.PUSDT ? item.bal.PUSDT.liquid : '') }}
        </template>

        <template v-slot:top >
          <v-dialog v-model="dialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">User: {{user.name}}</span>
                <span>{{user.phone}}</span> &nbsp;
                <a :href="explorerUrl() +user.accountName" target="telos" class="txt-link">{{user.accountName}}</a>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-btn-toggle dense v-model="tab">
                    <v-btn value="tasks">Tasks</v-btn>
                    <v-btn value="wallet">Wallet</v-btn>
                  </v-btn-toggle>
                  <br/><br/>

                  <v-row v-if="tab=='wallet'">
                    <ul>
                      <li v-for="b of user.balances" :key="'bal'+b.symbol">
                      <span style="width:200px;">
                        {{ b.symbol }}
                        {{ store.fmtAmount(b.balance, b.symbol, false, 1) }}

                        <span v-if="user.bal[b.symbol]">
                          <span v-if="user.bal[b.symbol].deposited">deposited: {{ store.fmtAmount(user.bal[b.symbol].deposited, b.symbol, false, 1) }}</span>
                          <span v-if="user.bal[b.symbol].staked">staked: {{ store.fmtAmount(user.bal[b.symbol].staked, b.symbol, false, 1) }}</span>
                        </span>

                      </span>
                        <span v-if="b.usdRate && b.usdRate != 1"> ({{ store.fmtBalance({balance: b.usdRate * b.balance, symbol:'USDT'}) }})</span>
                      </li>
                    </ul>
                  </v-row>
                  <br/>
                  <account-history v-if="tab=='wallet'" :user="user" />

                  <div v-if="tab=='tasks'">
                  <v-row v-for="v in user.tasks" :key="v.id">
                    <div v-if="v.cnt >0">
                      <b>{{v.id}}: {{v.cnt}}</b> &nbsp;&nbsp;
                      <span v-if="v.history">
                        <span v-for="h in v.history" style="color:gray">
                          {{ymd(h.date)}} {{h.admin}}: {{h.send ? h.send + '*' : h.cnt}}, </span>
                      </span>
                      <span v-else style="color:gray">{{ymd(v.date)}} {{v.admin}}: {{v.cnt}}</span>
                    </div>
                  </v-row>

                  <v-row style="margin-top:5px;">
                    <br><br>
                    <v-select :items="taskCodes"  label="tasks completed"  style="width:150px;"
                              @change="earnCnt = taskCnt(user, earnCode)"
                              item-text="code" item-value="code" v-model="earnCode"></v-select>
                    &nbsp;
                    <v-text-field v-model="earnCnt" type="number" dense
                                  style="width:30px;margin-left:5px;margin-top:22px;" ></v-text-field>
                    <div>
                    <v-btn text @click="saveEarn(user, true)">save & pay task</v-btn><br>
                    <v-btn text @click="saveEarn(user, false)" color="gray">save only</v-btn>
                    </div>
                  </v-row>

                  <v-row>
                    <v-select v-if="store.state.info" style="width:150px;"
                              :items="store.state.info.SkillLevels"  label="group/skill levels" multiple
                              :item-text="i => store.skillLevelName(i.id)" item-value="id" v-model="user.skillLevels"></v-select>
                    <v-btn text @click="saveSkill(user)" style="margin-top:15px;">save</v-btn>
                  </v-row>

                  <v-row>
                    <v-text-field v-model="user.phone" dense placeholder="phone"></v-text-field>&nbsp;
                    <v-btn text @click="savePhone(user)">save</v-btn>
                  </v-row>

                  <v-row>
                    <v-select :items="mentorCodes" :item-text="i=>i.mentor +' - ' + i.code"
                              :item-value="i=>i.code" style="width:150px;"
                              v-model="mentorCode" label="mentor code" ></v-select>
                    <v-btn text @click="saveMentorCode(user, mentorCode)" style="margin-top:15px;">save</v-btn>
                  </v-row>
<!--                  <v-row>-->
<!--                    <v-checkbox v-model="user.features" value="wallet" /> show wallet-->
<!--                    <v-btn text @click="saveFeatures(user)" style="margin-top:15px;">save</v-btn>-->
<!--                  </v-row>-->
                  </div>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="deleteUser(user)" color="error">Delete user</v-btn>

                <v-btn v-if="user.status <= 2" text @click="blockUser(user, 1)" color="error">block</v-btn>
                <v-btn v-else text @click="blockUser(user, 0)" color="success">unblock</v-btn>

                <v-btn v-if="user.role=='user'" text @click="save({role:'mentor'}, 'make mentor')">Make Mentor</v-btn>
                <v-btn v-else-if="user.role=='mentor'" text @click="save({role:'user'})">Undo Mentor</v-btn>
                <v-btn v-if="user.role!='admin'" text @click="save({role:'admin'}, 'make admin')">Make Admin</v-btn>
                <v-btn v-else-if="user.role=='admin'" text @click="save({role:'user'}, 'Undo admin')">Undo Admin</v-btn>

                <v-btn v-if="user.verifier" text @click="save({verifier:null}, 'unset verifier')">Unset Verifier</v-btn>
                <v-btn v-else text @click="save({verifier:1}, 'make verifier')">Make Verifier</v-btn>

                <v-btn text @click="dialog=false">Cancel</v-btn>

<!--                <v-btn text @click="save(user)">Save</v-btn>-->
              </v-card-actions>
            </v-card>
          </v-dialog>

        </template>


      </v-data-table>

      <v-card v-if="stats"  class="stats-card" >
        <v-sheet
            class="v-sheet--offset mx-auto"
            color="cyan"
            elevation="12"
            max-width="calc(100% - 32px)"
        >

<!--          <vue-apex-charts  type="area" height="500" :options="stats.chartOptions" :series="stats.series" />-->

          <!--          <v-sparkline-->
<!--              :labels="stats.labels"-->
<!--              :value="stats.values"-->
<!--              color="white"-->
<!--              line-width="2"-->
<!--              padding="16"-->
<!--          ></v-sparkline>-->
        </v-sheet>
      </v-card>


      <v-snackbar v-model="showInfo" text @click="showInfo=false" color="green">
        {{info}}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="showInfo = false"></v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>

<style>

.row-clk { cursor:pointer; }
</style>

<script>
import _ from 'lodash';
import AdminToggle from "./AdminToggle";
import AccountHistory from './AccountHistory'

// import VueApexCharts from 'apexcharts'

export default {
  name: 'AdminUsers',
  components: { AdminToggle, AccountHistory,
    // VueApexCharts
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi(0)
      },
      deep: true,
    },
  },
  data: () => ({
    error: '',
    users: [],
    search: '',
    country: '',
    mentorCodeSearch: '',
    skillLevel: 0,
    total: 0,
    loading: true,
    info: '', showInfo: false,
    tab: 'tasks',

    stats: null,
    earn: [],
    taskCodes: [],
    earnCode: '',
    earnCnt: 1,

    dialog: false,
    user: { earnCode:[] },
    editedIndex: -1,
    mentors: [],
    mentor: '',
    mentorCodes: [],
    mentorCode: '',

    options: {
      // 'items-per-page-options': [10,25,50,100],
    },
    headers: [
      // { text: 'id', value: 'id'},
      // { text: 'username', value: 'username' },
      { text: 'name', value: 'name' },
      { text: 'phone', value: 'phone' },
      { text: 'email', value: 'email' },
      { text: 'country', value: 'country' },
      { text: 'auth', value: 'authProvider' },
      { text: 'group/skill', value: 'skillLevels' },
      { text: 'signup', value: 'signupDate' },
      { text: 'status', value: 'status' },
      { text: 'phoneCode', value: 'phoneCode' },
      { text: 'last_login', value: 'lastLogin' },
      { text: 'role', value: 'role' },
      { text: 'tlos', value: 'accountName' },
      { text: 'mentorCode', value: 'mentorCode' },
      { text: 'mentor', value: 'mentor' },
      { text: 'learn', value: 'learnStatus', sortable:false },
      { text: 'earned', value: 'revenue' },
      { text: 'balance', value: 'bal.PUSDT.liquid' },     // TODO: store.WithdrawSymbol
      { text: 'staked', value: 'bal.PUSDT.staked' },
      { text: 'tasks', value: 'tasks', sortable:false },
      { text: 'done', value: 'tasksDone', sortable:false },
      { text: 'avail', value: 'tasksAvailable', sortable: false },

      { text: '', value: 'actions', sortable:false },
    ],
  }),
  methods: {
    load() {
      this.store.content(true).then(data => {
        this.earn = data.earn
        this.taskCodes = data.taskCodes
        console.log('codes', this.taskCodes)
      })
    },

    ymd(dt) { return (dt ? new Date(dt) : new Date()).toISOString().slice(0, 10) },

    taskCnt(user, code) {
      let e = _.find(user.tasks, {id: code})
      return e ? parseFloat(e.cnt ||0) : 0
    },
    editItem(item) {
      console.log('edit', item)
      this.editedIndex = this.users.indexOf(item)
      this.user = Object.assign({}, item)
      this.mentor = this.user.mentor // ? this.user.mentor.username : ''
      this.mentorCode = this.user.mentorCode
      this.earnCnt = 0
      if (this.earnCode) this.earnCnt = this.taskCnt(this.user, this.earnCode)
        // this.earnCnt = this.user.earnStatus[this.earnCode] ? this.user.earnStatus[this.earnCode].cnt : 0
      this.dialog = true
    },

    doSearch(s) {
      this.search = s
      this.getDataFromApi(1)
    },
    searchCountry(c) {
      this.country = c
      this.getDataFromApi(1)
    },
    searchSkill(sk) {
      this.skillLevel = sk
      this.getDataFromApi(1)
    },
    searchMentorCode(mc) {
      this.mentorCodeSearch = mc
      this.getDataFromApi(1)
    },

    getDataFromApi(reset) {
      this.loading = true
      if (reset) this.options.page = 1
      let { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.store.adminUsers(page - 1, itemsPerPage, sortBy, sortDesc, this.search,
          this.skillLevel||'', this.country||'', this.mentorCodeSearch||'').then(data => {
        this.users = data.users
        this.total = data.total
        this.mentors = data.mentors
        this.mentorCodes = []
        for (let m of data.mentors) {
          for (let mc of m.mentorCodes || [])
            this.mentorCodes.push({ mentor:m.username, ...mc })
        }
        // console.log('mc', this.mentorCodes)
        this.loading = false
      })
    },

    async save(u, msg) {
      if (this.editedIndex > -1) {
        if (msg && ! confirm(this.user.email + ' ' + msg)) return

        let ok = await this.store.adminUpdateUser(this.user.id, u)
        if (!ok || ok.error) {
          return alert(ok.error || 'error')
        }
        Object.assign(this.user, u)
        Object.assign(this.users[this.editedIndex], u)
      }
      this.dialog = false
    },
    async savePhone(u) {
      let ok = await this.store.adminUpdateUser(this.user.id, { phone: u.phone })
      if (!ok || ok.error) return alert(ok.error || 'error')

      this.user.phone = u.phone
      if (this.editedIndex > -1) this.users[this.editedIndex].phone = u.phone

      this.dialog = false
    },
    async saveFeatures(u) {
      let ok = await this.store.adminUpdateUser(this.user.id, {features: u.features})
      if (!ok || ok.error) return alert(ok.error || 'error')

      this.user.features = u.features
      if (this.editedIndex > -1) this.users[this.editedIndex].features = u.features
    },
    async setStatus(s) {
      await this.store.adminUpdateUser(this.user.id, { status:s })
      // this.user.status = s
    },
    async saveEarn(user, pay) {
      // TODO: adapt new fmt with history[]
      let s = { id: this.earnCode, status:1, cnt: this.earnCnt, admin:this.store.state.user.username, date:new Date() }
      let cnt = parseFloat(this.earnCnt ||0)
      console.log('saveEarn', user, this.earnCode, cnt)
      // upd['earnStatus.' + this.earnCode] = s
      // await this.store.adminUpdateUser(this.user.id, upd)

      // TODO: use dynamic token/currency also? should have 'symbol'
      let prvCnt = this.taskCnt(user, this.earnCode)
      let send = parseFloat((cnt - prvCnt).toFixed(2))  // number of entries. int ?? (backend:int)
      if (send < 0) return alert("can't remove " + send)
      if (this.earnCode && cnt > 0 && send > 0 &&
          confirm(`${pay ? 'PAY':'Save'}: ${user.name}, ${user.phone}\nTask: ${this.earnCode} : ${send}`)) {
          // to ${user.accountName}

        let ret = null
        try {
          ret = await this.store.adminUpdateEarnCount(this.user.id, this.earnCode, cnt, pay)
        } catch (e) { console.log('exception', e.toString()) }
        if (!ret || ret.error || ret.status=='error') return alert((ret && ret.error) || 'error saving')

        this.info = ret.info
        this.showInfo = true

        if (this.editedIndex) {
          let u = this.users[this.editedIndex]
          if (!u.tasks) u.tasks = []

          let i = _.findIndex(u.tasks, {id: this.earnCode})
          if (i !== -1) {
            s.history = u.tasks[i].history
            u.tasks[i] = s
          }
          else u.tasks.push(s)

          console.log('ed', this.editedIndex, u.tasks)
          u.revenue = (user.revenue || 0 ) + send
        }
        this.dialog = false
      }
    },

    async saveSkill(user) {
      // let s = { status:1, cnt: this.earnCnt, admin:this.store.state.user.username, date:new Date() }

      console.log('saveSkill', user, user.skillLevels)
      await this.store.adminUpdateUser(this.user.id, { skillLevels: user.skillLevels })

      if (this.editedIndex) {
        this.users[this.editedIndex].skillLevels = user.skillLevels
      }
      this.dialog = false
    },

    async saveMentorCode(user, mentorCode) {
      let mc = _.find(this.mentorCodes, {code: mentorCode})
      let mentor = mc ? mc.mentor : this.user.mentor
      let ok = await this.store.adminUpdateUser(this.user.id, { mentor: mentor, mentorCode: mentorCode })
      if (!ok || ok.error) return alert(ok.error || 'error')

      this.user.mentor = mentor
      this.user.mentorCode = mentorCode
      if (this.editedIndex > -1) {
        this.users[this.editedIndex].mentor = mentor
        this.users[this.editedIndex].mentorCode = mentorCode
      }
      this.dialog = false
    },


    async deleteUser(u) {
      if (!confirm('delete user '+ u.username)) return
      await this.store.deleteUser(u.id)
      this.users.splice(_.findIndex(this.users, {id:u.id}), 1)
      this.dialog = false
    },
    async blockUser(u, block) {
      if (!confirm((block ? 'block':'unblock') + ' user '+ u.username + '?')) return
      await this.store.blockUser(u.id, block)
      u.status = block ? u.status + 10 : u.status - 10
      if (this.editedIndex > -1) this.users[this.editedIndex].status = u.status

      this.dialog = false
    },

    explorerUrl() {
      return this.store.ChainNetwork == 'telos_main' ?
      'https://telos.eosx.io/account/' : 'https://telos-test.eosx.io/account/'
    },
    fmtAmount(b) {
      return b ? b.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : ''
    },

    taskStats(u) {
      return _.sumBy(u.tasks, 'cnt') || '-'
      // return (_.filter(u.tasks, t => t.cnt > 0).length || '-')
      // return u.tasksAvailable.length
    },

    async showStats(t) {
      this.stats = await this.store.adminStats('')
      // let dates = _.map(this.stats.stats.signups, '_id')
      // this.stats.values = _.map(this.stats.stats.signups, 'count')
      // if (this.stats.labels.length > 20) {
      //   for (let i = 0; i < this.stats.labels.length; i++) {
      //     let l = this.stats.labels[i]
      //     this.stats.labels[i] = l.match(/-01$/) ? this.stats.labels[i].replace(/-01$/, '') : ' '
      //   }
      // }

      let data = []
      for (let i = 0; i < this.stats.stats.signups.length; i++) {
        data.push({x: this.stats.stats.signups[i]._id, y: this.stats.stats.signups[i].count })
      }

      this.stats.series = [{
        name: 'Signups',
        data: data
      }]
      this.stats.stats.chartOptions = {
        chart: {
          type: 'area',
          stacked: false,
          height: 500,
          // zoom: {
          //   type: 'x',
          //   enabled: true,
          //   autoScaleYaxis: true
          // },
          // toolbar: {
          //   autoSelected: 'zoom'
          // }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        title: {
          text: 'Signups',
          align: 'left'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val
            //   return (val / 1000000).toFixed(0);
            },
          },
          title: {
            text: 'Price'
          },
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: false,
          y: {
            // formatter: function (val) {
            //   return val // (val / 1000000).toFixed(0)
            // }
          }
        }
      }
      console.log('stats.', this.stats)
    }
  },
  async beforeMount() {
    if (! this.store.isAdmin()) return this.$router.replace('/').catch(e => console.log(e))
    await this.load()
  },
}
</script>


<style>
.desc {
  white-space: pre-line;
}
.txt-link {
  text-decoration: none;
}

/* TODO: need media selector to adjust position */
.search-div {
  margin-left: 10px;
  width: 230px;
}
.skills-div {
  margin-left: 10px;
  width: 120px;
}
.skills-sel {
  flex:none;
  width:150px;
  margin-left:5px;
  position: relative;
  top: 6px;
}
.search-total {
  font-size: 14px;
}
.search {
}
.country-div {
  margin-left: 5px;
  padding-bottom: 6px;
}
.mentorcodesearch-div {
  margin-left: 5px;
  padding-bottom: 6px;
}

td.text-start {
  padding: 0px 0px 0px 10px !important;
}
td.text-start:first-child {
  min-width: 120px;
}
th.text-start {
  padding: 0px 0px 0px 10px !important;
  vertical-align: top;
}

.stats-card {
  position: absolute;
  top: 0;
  z-index: 200;
  width: 100%;
  height: 500px;
}
</style>
