<template>
  <v-btn-toggle v-model="sel" dense style="position:absolute;right:5px;top:5px;margin-left:10px;">
    <v-btn v-for="n of names" @click="nav(n)" >{{ n }}</v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  data: () => ({
    sel:0,
    names: [ 'Users', 'Mentors', 'Tasks', 'Giveaways', 'Skills']
  }),
  methods: {
    nav(r) {
      this.$router.push( 'admin-' + r.toLowerCase() )
    }
  },
  beforeMount() {
    this.sel = this.names.indexOf(this.$route.name)
  }
}
</script>