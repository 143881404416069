<template>
  <div class="login">
    <h1>Sign Up</h1>
    <br>

    <v-form @submit="register">
      <v-row>
        <v-col class="col-md-4 col-sm-6">
          <v-text-field v-model="user.username" outlined :label="$gettext('Username')" ></v-text-field>

          <v-text-field v-model="user.phone" outlined :label="$gettext('Phone')"
                        :rules="[store.rules.required, store.rules.phone]" ></v-text-field>

          <v-text-field v-model="user.email" outlined :label="$gettext('Email')" v-if="store.config.useEmail"
                        :rules="[store.rules.required, store.rules.email]" ></v-text-field>

          <v-text-field v-model="user.pin" outlined :label="$gettext('Pin')"
                        :append-icon="showPin ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[store.rules.required, store.rules.minPin]"
                        :type="showPin ? 'text' : 'password'"
                        @click:append="showPin = !showPin"
          ></v-text-field>

          <v-text-field v-model="user.signupCode" outlined :label="$gettext('Signup Code')"
                        :rules="[store.rules.required, store.rules.minCode]" ></v-text-field>

          <v-checkbox v-model="user.t_and_c" :label="$gettext('I accept the Terms and Conditions')" :rules="[store.rules.required]"></v-checkbox>

          <v-btn primary :disabled="user.username=='' || user.phone=='' || user.pin=='' || !user.t_and_c"
                 @click="register" type="submit">Sign Up</v-btn>

          <router-link to="/login" >Login</router-link>

          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>

        </v-col>
      </v-row>
    </v-form>
  </div>
</template>


<script>
// import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'Register',
  components: {
    // VueRecaptcha
  },
  data: () => ({
    showPin: false,
    user: {
      username: '',
      email: '',
      phone: '',
      pin: '',
      signupCode: '',
    },
    error: '',
  }),
  methods: {
    register(e) {
      this.error = ''
      console.log('reg', this.user)
      e.preventDefault()
      let self = this

      grecaptcha.ready(function() {
        grecaptcha.execute(self.store.config.captchaKey, {action: 'submit'}).then(function(captcha) {
          self.user.captcha = captcha

          self.store.register(self.user)
              .then(res => {
                self.error = res.error || ''
                console.log('reg:', res.error||'-', res.status)
                if (res.status === 'ok') self.$router.replace('/verify-phone')
              })

        })
            .catch(err => { self.error = err || 'Captcha Error' })
      });

    },
    onVerify(r) {
      console.log('captcha verify', r)
    },
    onExpired() {
      console.log('captcha expired')
    }
  }
}
</script>
