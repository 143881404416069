<template>
  <div class="">
    <v-card>
      <v-card-title >
        Giveaway Access
        <v-icon style="color:gray;margin-left:10px;" @click="reload()">mdi-refresh</v-icon>

        <admin-toggle />

      </v-card-title>
      <v-card-text>
        <div>
          <v-select placeholder="giveaway rounds"
              :items="rounds" :item-text="r => '#' + r.round_id + ' - ' + r.round_name"
                    :item-value="r => r" v-model="round" @change="selectRound(round)"></v-select>
        </div>
        <div>
          <v-select placeholder="giveaway templates"
              :items="templateList" :item-text="t => t.name + ' / ' + t.id"
                    :item-value="r => r" v-model="edit" @change="selectTemplate(edit)"></v-select>
        </div>
      </v-card-text>

      <v-card-text v-if="a">
<!--        <h3>#{{round.round_id}} - {{round.round_name}}</h3>-->


        <div v-if="a.template" >
          <div><v-text-field v-model="a.name" label="name" /></div>
          <div><v-text-field v-model="a.description" label="description" type="" :clearable="true"/></div>
          <br/>

          <div><v-text-field v-model="a.round.term_id" label="term #" type="number"/></div>
          <div><v-text-field v-model="a.round.entry_stake" label="entry stake" type=""/></div>
          <div><v-text-field v-model="a.round.total_reward" label="total reward" type=""/></div>
          <div><v-text-field v-model="a.round.num_participants" label="participants" type="number"/></div>
          <div><v-text-field v-model="a.round.staking_period_hrs" label="staking period hours" type="number"/></div>
          <div><v-text-field v-model="a.round.enrollment_time_out_hrs" label="enrollment timeout hours" type="number"/></div>

          <div>
            <v-text-field v-model="a.beneficiary_name" label="beneficiary name" type="" :clearable="true"/>
          </div>

          <div><v-checkbox v-model="a.autoCreate" label="automatically renew (create new) when full/expired"/></div>

          <div v-if="a.autoCreateError" class="error">
            {{ a.autoCreateError }}
            <br/>
          </div>

          <div v-if="a.autoCreateTx">
            Created rounds: (#{{ a.counter }})<br/>
            <ul>
              <li v-for="tx in a.autoCreateTx">{{tx.name}} / {{tx.date}}</li>
            </ul>
          </div>
          <br/>
        </div>

        <div v-else-if="round">

          <div>name: {{round.round_name}}</div>
          <div v-if="a.template_id">template: {{ a.templateName }} </div>

          <div>
            <v-text-field v-model="a.description" label="description" type="" :clearable="true"/>
          </div>
          <br/>

          <div>term: {{round.term_id}} {{ round.terms ? ' - ' + round.terms.term_name : ''}}</div>
          <div>stake: {{round.entry_stake}}</div>
          <div>prize: {{round.total_reward}}</div>
          <div>participants: {{round.num_participants }}  ({{round.num_participants_entered}})</div>
          <div>state: {{round.current_state}}</div>

          <div v-if="round.terms && round.terms.beneficiary_perc_x100000">
            <div>
              beneficiary: {{ round.terms.beneficiary }}
              - {{ round.terms.beneficiary_perc_x100000 / 100000 }}% <br/>
<!--              term name: {{ round.terms.term_name }}-->
            </div>
            <div>
              <v-text-field v-model="a.beneficiary_name" label="beneficiary name" type="" :clearable="true"/>
            </div>
          </div>
        </div>


        <div><v-checkbox v-model="a.autoEnrollSignup" label="auto-enroll eligible users at signup"/></div>
        <div><v-checkbox v-model="a.autoEnrollExisting" label="auto-enroll existing users at login"/></div>
        <div><v-select v-model="a.autoEnrollMode" label="auto-enroll mode"
          :items="['enroll-only', 'open', 'open-once']"  :disabled="! a.autoEnrollSignup && !a.autoEnrollExisting"
        /></div>

        <br/><br/>
<!--        <div>-->
<!--          <div v-if="a.use_start">start: {{ a.start }}</div>-->
<!--          <div >all: {{ !! a.all }}</div>-->
<!--        </div>-->

        <h4>Access</h4>
        <v-row>
          <v-col sm="6" class="sm-col">
            <v-checkbox v-model="a.use_start" label="set start"></v-checkbox>
            <v-checkbox v-if="a.use_start" v-model="a.show_coming" label="show coming soon"></v-checkbox>
          </v-col>

          <v-col sm="6" style="min-height:40px">
            <v-card>
              <v-card-title>Access Settings Summary</v-card-title>
              <v-card-text>
                <div>start: {{ a.use_start ? store.fmtDateTime(a.start) : '-' }}
                  <span v-if="a.use_start && a.show_coming">/ show coming soon</span>
                </div>
                <div>all: {{ a.all ? '✔':'✘'}}</div>
                <div v-if="! a.all">
                  <div v-for="s,i in a.skills" v-if="s">{{ store.skillLevelName(i) }} ✔</div>
                  <br/>
                  <div v-for="c,i in a.codes" v-if="c">{{i}} ✔</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="a.use_start">
          <v-col sm="4" class="sm-col">
            <v-text-field v-model="start" @change="saveStart()" label="start date" type="datetime-local" :clearable="false"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="4" class="sm-col">
            <v-checkbox v-model="a.all" label="allow all"></v-checkbox>
          </v-col>
        </v-row>

        <v-btn @click="save()" class="primary">SAVE</v-btn>
        <v-btn v-if="a.template && a.id" @click="deleteTemplate(a)" class="error">Delete</v-btn>

        <v-btn v-if="a.template" @click="createGiveaways()" class="primary">create giveaway</v-btn>

        <br><br/>

        <h4>Skill Levels</h4>
        <div><i>if selected then ignores mentor code</i></div><br><br>


        <v-row v-for="s in store.state.info.GroupsSkillLevels" :key="s.id">
          <v-col sm="5" class="sm-col">{{ store.skillLevelName(s.id) }}</v-col>
          <v-col sm="1" class="sm-col"><v-checkbox v-model="a.skills[s.id]" class="check"/></v-col>
        </v-row>

        <br><br>
        <h4>Mentor Codes</h4>
        <div v-for="u in mentorCodes" :key="u.username">
          <v-row><v-col sm="4"><b>{{ u.username}}</b></v-col></v-row>

          <v-row v-for="m in u.mentorCodes" :key="m.code">
            <v-col sm="3" class="sm-col">{{ m.code }}</v-col>
            <v-col sm="1" class="sm-col"><v-checkbox v-model="a.codes[m.code]" class="check"/></v-col>
          </v-row>
        </div>

        <br/>
<!--          <v-checkbox v-model="a.template" label="use as template" />-->
        <br/>

        <v-btn @click="save()" class="primary">SAVE</v-btn>

        <v-btn v-if="! a.template && ! a.template_id" @click="createTemplate(a)" >Create Template</v-btn>
      </v-card-text>

      <v-btn @click="newTemplate()" >New Template</v-btn>

    </v-card>
  </div>
</template>



<script>
// restrict access to giveaways per skill level or mentor code. admin ui

import _ from "lodash";
import AdminToggle from "@/views/AdminToggle";

export default {
  name: 'AdminUsers',
  components: { AdminToggle },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  data: () => ({
    error: '',
    total: 0,
    loading: true,
    rounds: [],
    round: null,
    skillLevel: null,
    upd: null,
    mentorCodes: [],
    activeList: {},
    templateList: [],
    edit: null,
    a: null,
    start: null,
    dateMenu:false,
  }),

  methods: {
    async load() {
      await this.store.content(true)
      await this.reload()
    },
    async reload() {
      let s = await this.store.loadVarexData(true, false , true)
      if (s && s.rounds) this.rounds = s.rounds

      let data = await this.store.adminMentorCodes()
      this.mentorCodes = data.mentorCodes

      this.activeList = await this.store.adminGiveawaysAccess()
      this.templateList = _.filter(_.values(this.activeList), {template:true })
// TODO: add status / delete  or template:'delete'

      console.log('init', this.mentorCodes, this.rounds && this.rounds.length, this.rounds,
          this.activeList && this.activeList.length, this.activeList,
          this.templateList && this.templateList.length, this.templateList)
    },


    selectRound(r) {
      if (!r) return
      this.edit = null
      if (!this.activeList[r.round_id]) {
        console.log('no activelist', r.round_id)
        this.activeList[r.round_id] = { round_id:r.round_id, start:r.created_date || new Date().toISOString(), use_start:false, all:false, skills:{}, codes:{}, }
      }
      this.a = this.activeList[r.round_id]
      this.start = this.a.start ? this.a.start.replace(/Z$/,'') : null  // the textfield does not like the 'Z'
      console.log('r', r.round_id, r, this.start, this.a)

      if (this.a.template_id) {
        let t = _.find(this.templateList, {id: this.a.template_id})
        this.a.templateName = t ? t.name : '-'
      }
      return this.a
    },
    selectTemplate(e) {
      if (!e) return
      console.log('edit', e)

      if (!e.skills) e.skills = {}
      if (!e.codes) e.codes = {}
      if (!e.start) e.start = new Date().toISOString()

      // round_id:r.round_id, start:r.created_date || new Date().toISOString(), use_start:false, all:false, skills:{}, codes:{}, }

      this.a = this.edit = e
      this.round = null
      console.log('sel', this.a)
      // this.edit = e
    },
    newTemplate() {
      this.round = {}
      let a = { round: this.round }
      a.round_id = a.id = a.round.round_id = this.store.mkToken()

      this.selectTemplate(a)

      a.template = true
      if (!a.round.staking_period_hrs) a.round.staking_period_hrs = 7 * 24
      if (!a.round.enrollment_time_out_hrs) a.round.enrollment_time_out_hrs = 24
      if (!a.name) a.name = a.round.round_name

      this.edit = this.a = a
      console.log('new', this.a)
      // this.initTemplate(this.a)
    },

    createTemplate(a) {
      let n = _.cloneDeep(a)
      this.round = n.round = _.cloneDeep(this.round)
      this.a = n

      // n.round = {}
      // for (let k of ['term_id', 'num_participants', 'entry_stake', 'total_reward' ]) {
      //   n.round[k] = a.round[k]
      // }
      // n.round.staking_period_hrs = 7 * 24   // parse from secs
      // n.round.enrollment_time_out_hrs = 24
console.log('create', a, n)
      this.initTemplate(n)
    },

    async deleteTemplate(a) {
      if (confirm('delete template ' + a.name)) {
        await this.store.deleteGiveawayTemplate(a.id)
        this.reload()
        this.newTemplate()
      }
    },
    async initTemplate(a) {
      if (!a.round) a.round = {}
      a.round_id = a.id = a.round.round_id = this.store.mkToken()
      a.template = true
      if (!a.round.staking_period_hrs) a.round.staking_period_hrs = 7 * 24
      if (!a.round.enrollment_time_out_hrs) a.round.enrollment_time_out_hrs = 24
      if (!a.name) a.name = a.round.round_name

      this.a = a
      await this.save()
      this.edit = a
      this.round = null
      console.log('init t', a)
      await this.reload()
    },

    async createGiveaways() {
      // let num = parseInt(prompt('create giveaways') ||0)
      let num = 1
      if (num) {
        this.a.counter = (this.a.counter || 0) + num
        let res = await this.store.adminCreateGiveaways(this.a.id, num, this.a.counter)
        if (!res.tx) alert(res.error.error || 'error')
        if (res.tx) alert('created giveaway: ' + res.name + '\ntx: ' + res.tx)
        await this.reload()
        setTimeout(this.reload, 20 * 1000)
      }
    },

    saveStart() {
      this.a.start = new Date(this.start).toISOString()
      console.log('set start', this.start, this.a.start)
    },
    async save() {
      await this.store.adminUpdateGiveawayAccess(
          this.a.round ? this.a.round.round_id : this.round.round_id, this.a)
      this.reload()
    }
  },

  async beforeMount() {
    await this.store.reloadUser(true)
    if (! this.store.isAdmin()) return this.$router.replace('/').catch(e => console.log(e))
    await this.load()

    if (this.rounds && this.rounds.length) {
      console.log('mnt', this.rounds.length, this.rounds)
      this.selectRound(this.rounds[0])
      this.round = this.rounds[0]
    }

    // this.upd = setInterval(this.reload, 30 * 1000)
  },
}

</script>



<style>
.check {
  padding: 0;
  height: 16px;
  margin-top:0;
}
.sm-col {
  padding-top: 0;
}
</style>
