<template>
  <div class="">
    <v-card>
      <v-card-title >
        Admin
        <v-icon style="color:white;margin-left:10px;" @click="reload()">mdi-refresh</v-icon>

        <admin-toggle />

      </v-card-title>
      <v-card-text v-if="show=='mentors'">

        <h4>Mentors / Codes

          <v-btn @click="show='countries'">Countries</v-btn>
        </h4>

        <v-row>
          <v-col sm="2" class="sm-col"></v-col>
          <v-col sm="4" class="sm-col">skill</v-col>
          <v-col sm="2" class="sm-col">welcome</v-col>
          <v-col class="sm-col">users</v-col>
          <v-col class="sm-col">tasks joined</v-col>
          <v-col class="sm-col">count</v-col>
          <v-col class="sm-col">revenue</v-col>
        </v-row>

        <div v-for="u in mentorCodes" :key="u.username">
          <v-row><v-col sm="6"><b>{{ u.username}}</b></v-col></v-row>

          <v-row v-for="m in u.mentorCodes" :key="m.code">
            <v-col sm="2" class="sm-col" style="margin-left:10px;" @click="openEdit(u, m)"><span class="clk">{{ m.code }} </span>
              <span class="error" v-if="!m.status">inactive</span>
            </v-col>
            <v-col sm="4" class="sm-col">{{ store.skillLevelName(m.skillLevel)}} </v-col>
            <v-col sm="2" class="sm-col">{{ m.welcomePage || '-'}} </v-col>
            <v-col class="sm-col">{{ stats(m.code).users }}</v-col>
            <v-col class="sm-col">{{ stats(m.code).tasksJoin || '' }}</v-col>
            <v-col class="sm-col">{{ stats(m.code).cnt || ''}}</v-col>
            <v-col class="sm-col">{{ stats(m.code).revenue || ''}}</v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text v-else-if="show=='countries'">
        <h4>Mentors / Countries
          <v-btn @click="show='mentors'">Codes</v-btn>

          <v-btn @click="isEdit=!isEdit" icon class="toggle-btn" ><v-icon >mdi-pencil</v-icon></v-btn>
        </h4>

        <br/>
        <v-card v-if="isEdit && selC.length" style="position:fixed; top:200px;right:20px;">
          <v-card-text>
          <v-select style="width:250px;"
                    :items="mentorCodesAll"  label="assign mentor code"
                    item-text="code" item-value="code" v-model="setMC" @change="assignMC(setMC)"></v-select>
          <v-btn @click="assignMC(setMC)" dense :disabled="!selC.length">set</v-btn>
          <v-btn @click="assignMC('*required*')" dense :disabled="!selC.length" >*required*</v-btn>
          <v-btn @click="assignMC('')" dense :disabled="!selC.length" icon>✘</v-btn>

          {{ selC.length }}
          </v-card-text>
        </v-card>

        <v-row>
          <v-col sm="1" class="sm-col"></v-col>
          <v-col sm="3" class="sm-col">country</v-col>
          <v-col sm="4" class="sm-col">mentor</v-col>
        </v-row>

        <v-row dense>
          <v-col sm="1">--</v-col>
          <v-col sm="3"><i>default</i></v-col>
          <v-col sm="3">{{ defaultMentorCodes.default || '-' }}</v-col>
          <v-col sm="1" v-if="isEdit">
            <v-checkbox v-model="selC" multiple dense value="default" ></v-checkbox>
          </v-col>
        </v-row>

        <v-row v-for="c in countryList" :key="c.code"  dense>
          <v-col sm="1">{{ c.code.substr(0,2) }}</v-col>
          <v-col sm="3">{{ c.name }}</v-col>
          <v-col sm="3">{{ defaultMentorCodes[c.code.substr(0,2)] || '-' }}</v-col>
          <v-col sm="1" v-if="isEdit">
            <v-checkbox v-model="selC" multiple dense :value="c.code.substr(0,2)" @click="toggleSel(c.code.substr(0,2))"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <div v-if="edit" class="mentor-outer" @click="edit=null">
      <v-card class="mentor-box" @click.stop="">
        <v-icon @click="edit=null" style="color:black; position:absolute;right:10px;">fa fa-times</v-icon>
        <h4>Edit {{edit.id}}</h4>
        <v-text-field style="width:150px;" v-model="edit.code" :rules="[store.rules.required, store.rules.minCode]"
                      :label="$gettext('Mentor Code')" ></v-text-field>
        <v-select style="width:250px;"
                  :items="store.state.info.SkillLevels"
                  :label="$gettext('Skill Level')"
                  :item-text="i => store.skillLevelName(i.id)" item-value="id" v-model="edit.skillLevel"></v-select>
        <v-checkbox v-model="edit.status" label="active"/>

        <v-select style="width:250px;"
                  :items="store.config.WelcomePages"
                  :label="$gettext('Welcome Page')"
                  v-model="edit.welcomePage"></v-select>

        <v-btn @click="saveCode(edit)" class="primary" v-translate>Save</v-btn> &nbsp;
        <v-btn @click="deleteCode(edit.code)" v-if="!stats(edit.id).users" class="error" v-translate>Delete</v-btn>
        <br/><br/>
        <hr>

        <v-select style="width:250px;"
                  :items="mentorCodes"  label="move code to mentor"
                  item-text="username" item-value="id" v-model="edit.movetoMentor"></v-select>
        <v-btn @click="moveCode(edit.code, edit.movetoMentor)" v-translate>Move</v-btn>

<br/>
        <v-btn @click="getCodeKey(edit.code)" v-translate>Get Key</v-btn>
        <v-btn @click="edit=null" class="secondary" v-translate>Close</v-btn>

        <div>{{codeKey}}</div>
      </v-card>
    </div>

  </div>
</template>



<script>

import _ from "lodash";
import AdminToggle from "@/views/AdminToggle";

export default {
  name: 'AdminMentors',
  components: { AdminToggle },
  data: () => ({
    show: 'mentors',
    error: '',
    total: 0,
    loading: true,
    skillLevel: null,
    mentorCodes: [],
    mentorCodesAll: [],
    mentorStats: {},
    defaultMentorCodes: {},
    countries: [],
    edit: null,
    selC: {},
    setMC: 0,
    isEdit: false,
    codeKey:'',
  }),

  methods: {
    async load() {
      await this.store.content(true)
      await this.reload()
    },
    async reload() {
      let data = await this.store.adminMentorCodes()
      this.mentorCodes = _.sortBy(data.mentorCodes, m => m.username)
      for (let s of data.mentorStats) this.mentorStats[s._id] = s

      this.mentorCodesAll =
          _.sortBy(_.filter(_.flatMap(this.mentorCodes, m => m.mentorCodes),
        m => m.status), 'code')

      console.log('#', this.mentorCodesAll)
      this.countries = data.countries
      this.defaultMentorCodes = data.DefaultMentorCodes
    },
    stats(code) {
      return this.mentorStats[code] || {}
    },

    openEdit(u, m) {
      // if (this.edit) this.edit = null; else
      this.edit = _.clone(m)
      this.edit.id = this.edit.code
      this.edit.username = u.username
      this.edit.movetoMentor = u.username
      this.codeKey = ''
    },

    saveCode() {
      this.store.editMentorCode(this.edit.id, this.edit.code, this.edit.skillLevel,
          this.edit.status? 1 : 0, this.edit.welcomePage).then(res => {
        console.log('res', res)
        this.error = res.error || ''
        if (!this.error) {
          let mc = _.find(this.mentorCodes, {username: this.edit.username})
          if (mc) {
            let m = _.find(mc.mentorCodes, {code: this.edit.id})
            m.code = this.edit.code
            m.status = this.edit.status
            m.skillLevel = this.edit.skillLevel
            m.welcomePage = this.edit.welcomePage
          }
          this.edit = null
        }
      })
    },
    async deleteCode(code) {
      if (confirm("delete? "+code)) {
        await this.store.deleteMentorCode(code)
        let mc = _.find(this.mentorCodes, {username: this.edit.username})
        _.remove(mc.mentorCodes, {code: code})
        this.edit = null
      }
    },
    async moveCode(code, mentor) {
      if (confirm(`move mentor code ${code} to mentor ${mentor} ?`)) {
        await this.store.moveMentorCode(code, mentor)
        this.edit = null
        this.reload()
      }
    },

    async getCodeKey(code) {
      let res = await this.store.getCodeKey(code)
      alert(res.codeKey)
      this.codeKey = res.codeKey
      console.log(code, res, this.edit)
    },

    toggleSel(c) {
      console.log('t',c, this.selC)
    },
    assignMC(code) {
      console.log('a', this.selC, code, this.setMC)
      for (let c of this.selC) {
        this.defaultMentorCodes[c] = code
      }
      this.selC = []
      this.store.saveDefaultMentorCodes(this.defaultMentorCodes)
    },

  },
  async beforeMount() {
    await this.load()
    await this.store.reloadUser(true)
    if (! this.store.isAdmin()) return this.$router.replace('/').catch(e => console.log(e))
  },

  computed: {
    countryList() {
      return _.filter(this.countries, c =>
          (c.code.length == 2 || c.code.match(/..1/)) &&
          (this.isEdit || this.defaultMentorCodes[c.code.substr(0,2)]) );
    }
  }
}

</script>



<style>
.sm-col {
  padding-top: 0;
}
.mentor-box {
  position: fixed;
  top: 100px;
  left: 20px;
  border: 1px solid gray;
  border-radius: 5px;
  background: grey;
  padding: 10px;
}
.mentor-outer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, .5);
}

</style>
