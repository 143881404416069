<template>
  <div class="login">
    <v-icon @click="back()" style="color:gray; position:absolute;right:100px;">fa fa-times</v-icon>
    <h3>Edit {{ mode }} #{{ id || 'New'}}: {{ e.title}}</h3>
    <br>

    <v-form @submit="edit">
      <v-row>
        <v-col class="col-md-6 col-sm-6">
          <v-checkbox v-model="e.status" label="active"></v-checkbox>
          <v-text-field v-if="mode=='learn'" v-model="e.order" outlined type="number" :rules="[store.rules.required]" label="Order #" ></v-text-field>

          <v-text-field v-model="e.title" outlined label="Title" :rules="[store.rules.required]"></v-text-field>
          <v-textarea v-model="e.description" outlined label="Description" :rules="[store.rules.required]" ></v-textarea>
          <v-textarea v-model="e.videoId" outlined label="Youtube Video Id / Embed Code"></v-textarea>

<!--          <v-select :items="langs"  label="Language" outlined-->
<!--                    item-text="name" item-value="id" v-model="e.language"></v-select>-->
          <v-btn-toggle v-model="e.language">
            <v-btn toggle dense v-for="l of langs" :value="l.id" >{{ l.name }}</v-btn>
          </v-btn-toggle>


          <v-select :items="store.state.info.GroupsSkillLevels"  label="skill levels" outlined multiple
                    :item-text="i => store.skillLevelName(i.id)" item-value="id"
                    v-model="e.skillLevels"></v-select>

          <v-btn class="primary" @click="edit" type="submit">{{ id ? 'Save' : 'Add'}}</v-btn>
          <v-btn class="error" @click="deleteEntry" type="submit">Delete</v-btn>
          <v-btn default @click="$router.back()" >Back</v-btn>

          <div class="error" v-if="error">{{ error }}</div>
        </v-col>
      </v-row>
    </v-form>

    <br><br>
<!--    * note: use order# 0 for the Welcome page content-->
  </div>
</template>


<script>
import _ from "lodash";
import { getIdFromURL } from 'vue-youtube-embed'

export default {
  name: 'Register',
  components: {
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    mode: String,
  },
  data: () => ({
    e: {},
    error: '',
  }),

  methods: {
    edit(e) {
      console.log('edit', this.e)
      e.preventDefault()

      if (!this.e.skillLevels || !this.e.skillLevels.length)
        this.e.skillLevels = [ 0 ];

      // extract youtube videoId from url?
      if (this.e.videoId && this.e.videoId.match(/^https:\/\/\w+?\.youtube\.com/)) {
        this.e.videoId = getIdFromURL(this.e.videoId)
      }

      if (!this.e.language) delete this.e.language

      this.store.saveContent('learn', this.e)
          .then(res => {
            this.error = res.error || ''
            console.log('save', res.error, res.status)
            if (res.status === 'ok') this.back()
          })
    },
    async deleteEntry(e) {
      console.log('delete', this.e)
      e.preventDefault()
      if (confirm('delete?')) {
        await this.store.deleteContent('learn', this.e.id)
        this.back()
      }
    },
    back() {
      this.$router.push(this.mode=='learn' ? '/learn' : '/')
    }
  },
  computed: {
    langs() {
      return [{id:'', name:'All'}].concat(_.map(this.store.config.Languages, l => ({id:l, name:l})))
      // return _.map(this.store.config.Languages, l => ({id:l, name:l}))
    }
  },
  beforeMount() {
    console.log('mount', this.mode, this.id)
    if (this.id)
      this.e = this.store.learnId(this.id)

    if (!this.e.language) this.e.language = 0

    if (this.mode=='home') this.e.order = 0
  }
}
</script>
